/* eslint-disable import/prefer-default-export */
import React from 'react'

import 'reset-css'
import './src/styles/global.css'
import { mobileBP } from './src/styles/breakpoint'

import ThemeSwitcher from './src/components/ThemeSwitcher'

let scrollPosition = 0

export const wrapPageElement = ({ element, props }) => (
  <ThemeSwitcher {...props}>{element}</ThemeSwitcher>
)

export const onPreRouteUpdate = () => {
  const sidebar = document.querySelector('#sidebar')

  if (sidebar) {
    scrollPosition = sidebar.scrollTop
  }
}

export const onRouteUpdate = () => {
  const sidebar = document.querySelector('#sidebar')

  if (sidebar && window.innerWidth > mobileBP) {
    sidebar.scrollTop = scrollPosition
  }
}

export const mobileBP = 700
export const smallBP = 1100
export const mediumBP = 1400

export const mobile = `(max-width: ${mobileBP}px)`
export const mobileMin = `(min-width: ${mobileBP + 1}px)`

export const small = `(max-width: ${smallBP}px)`
export const smallMin = `(min-width: ${smallBP + 1}px)`

export const medium = `(max-width: ${mediumBP}px)`

import React, { createContext, useState, useEffect } from 'react'

const defaultThemeState = { theme: 'light', setTheme: () => {} }

export const ThemeContext = createContext(defaultThemeState)

export default function ThemeSwitcher({ children }) {
  const [theme, setTheme] = useState('light')

  useEffect(() => {
    if (theme === 'light') {
      document.documentElement.setAttribute('data-theme', 'light')
    } else {
      document.documentElement.setAttribute('data-theme', 'dark')
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
